import { IContactCardsProps } from './ContactCard/ContactCard';

export type IContactProfilesMeta = {
    title: string,
    time: string
}
export interface IContactProfiles {
    tech: {
        meta: IContactProfilesMeta,
        items: IContactCardsProps[]
    }
    methodist: {
        meta: IContactProfilesMeta,
        items: IContactCardsProps[]
    }
    deanery: {
        meta: IContactProfilesMeta,
        items: IContactCardsProps[]
    }
    admins: {
        meta: IContactProfilesMeta,
        items: IContactCardsProps[]
    }
}

export const contactsProfiles: IContactProfiles = {
    methodist: {
        meta: {
            title: 'Методисты',
            time: 'пн - чт 10:00 - 18:00 мск, пт 10:00 - 17:00 мск'
        },
        items: [
            {
                title: 'Иванова Алёна Викторовна',
                description: 'Заместитель начальника отдела "Инновационные технологии в образовании"',
                phones: ['8 800 3333 77 6 (доб. 46)'],
                emails: ['a.ivanova@eeip.ru'],
                avatarLink: '../image/peoples/00004.jpg',
                uuid: 'd731c871-5daa-465f-b977-c0ade4cfd4fc'
            },

            {
                title: 'Алферова Наталья Алексеевна',
                description: 'Куратор бакалавриата (2 курс, набор 2023)',
                phones: ['8 800 3333 77 6 (доб. 21)'],
                emails: ['n.alferova@eeip.ru'],
                uuid: '3defadff-386a-41b5-a0e1-55aad0f73f98'
            },

            {
                title: 'Смолина Ксения Александровна',
                description: 'Куратор магистратуры',
                phones: ['8 800 3333 77 6 (доб. 44)'],
                emails: ['k.smolina@eeip.ru'],
                uuid: '640320e3-6d58-438c-93d6-6c0b72fd1bab',
                avatarLink: '../image/peoples/methodists/00003.jpg'
            },

            {
                title: 'Серебряников Илья Михайлович',
                description: 'Куратор ДПО "Психологическое консультирование", "Семейное консультирование", "Теоретические и практические работы с детьми и подростками"',
                phones: ['8 800 3333 77 6 (доб. 45)'],
                emails: ['i.serebryanikov@eeip.ru'],
                avatarLink: '../image/peoples/00013.jpg',
                uuid: '31567e9a-c5b1-4fa4-9618-4f0da2ca2936'
            },

            {
                title: 'Ивонина Анна Ивановна',
                description: 'Куратор ДПО "Психоанализ"',
                phones: ['8 800 3333 77 6 (доб. 68)'],
                emails: ['a.ivonina@eeip.ru'],
                uuid: '8316b001-0416-40e1-9908-255fbfef8a07',
                avatarLink: '../image/peoples/methodists/00002.jpg'
            },

            {
                title: 'Ахтырцева Христина Александровна',
                description: 'Специалист отдела "Инновационные технологии в образовании"',
                phones: ['8 800 3333 77 6 (доб. 45)'],
                emails: [' h.akhtyrtseva@eeip.ru', 'ha@eeip.ru'],
                uuid: 'd8833e4f-a241-4d4c-bf46-091168928c30',
                avatarLink: '../image/peoples/methodists/00001.jpg'
            },

            {
                title: 'Телешева Екатерина Витальевна',
                description: 'Куратор бакалавриата (1 курс, набор 2024; 3 курс, набор 2022)',
                phones: ['8 800 3333 77 6 (доб. 32)'],
                emails: ['e.telesheva@eeip.ru'],
                uuid: 'ad3b48ef-7849-482f-99c6-7ea209198dc6',
                avatarLink: '../image/peoples/methodists/00004.jpg'
            }
        ]
    },
    deanery: {
        meta: {
            title: 'Деканат (инспекторы)',
            time: 'пн - чт 10:00 - 18:00 мск, пт 10:00 - 17:00 мск'
        },
        items: [
            {
                title: 'Алексеева Елена Валерьевна',
                description: 'Старший инспектор деканата (бакалавриат, вечернее отделение)',
                phones: ['8 800 3333 77 6 (доб. 15)', '235-11-39'],
                emails: ['e.alekseeva@eeip.ru'],
                avatarLink: '../image/peoples/00001.jpg'
            },
            {
                title: 'Глазунова Светлана Валерьевна',
                description: 'Инспектор деканата (магистратура)',
                phones: ['8 800 3333 77 6 (доб. 17)', '235-11-39'],
                emails: ['s.glazunova@eeip.ru'],
                avatarLink: '../image/peoples/00002.jpg'

            },
            {
                title: 'Игушкина Мария Михайловна',
                description: 'Инспектор деканата (2 и 3 курс бакалавриата, дистанционное и заочное отделение)',
                phones: ['8 800 3333 77 6 (доб. 14)', '235-11-39'],
                emails: ['m.igushkina@eeip.ru'],
                avatarLink: '../image/peoples/00006.jpg'

            },
            {
                title: 'Степанова Юлия Владимировна',
                description: 'Специалист по дополнительному профессиональному образованию',
                phones: ['8 800 3333 77 6 (доб. 25)'],
                emails: ['stepanova@eeip.ru', 'dpo@eeip.ru'],
                avatarLink: '../image/peoples/00015.jpg'

            },
            {
                title: 'Шкомбарова Елена Валентиновна',
                description: 'Заместитель декана по ДПО',
                phones: ['8 800 3333 77 6 (доб. 25)'],
                emails: ['e.shkombarova@eeip.ru', 'dpo@eeip.ru']
            },
            {
                title: 'Алютина-Красникова Анастасия Станиславна',
                description: 'Инспектор деканата (1 курс бакалавриат, дистанционное отделение)',
                phones: ['8 (800) 3333-77-6 (доб. 14)'],
                emails: ['a.krasnikova@eeip.ru'],
                avatarLink: '../image/peoples/00020.png'

            }
        ]
    },
    tech: {
        meta: {
            title: 'Тех. поддержка',
            time: 'пн - пт 10:00 - 21:00 мск'
        },
        items: [
            {
                title: 'Пименов Владислав Александрович',
                description: 'Системный администратор (техническая поддержка)',
                phones: ['8 800 3333 77 6 (доб. 911)', '235-11-39'],
                emails: ['v.pimenov@eeip.ru', 'support@eeip.ru'],
                uuid: 'b1b77db9-329c-4940-a3ec-f5aea7ac0073'
            },

            {
                title: 'Цариков Никита Александрович',
                description: 'Системный администратор (техническая поддержка)',
                phones: ['8 (800) 3333-77-6 (доб. 911)', '235-11-39'],
                emails: ['n.tsarikov@eeip.ru ', 'support@eeip.ru'],
                avatarLink: '../image/peoples/00018.jpg',
                uuid: '51832923-8db7-455e-8cde-1e27b1b31f70'
            }
        ]
    },
    admins: {
        meta: {
            title: 'Администрация',
            time: 'пн - чт 10:00 - 18:00 мск, пт 10:00 - 17:00 мск'
        },
        items: [

            {
                title: 'Смарышева Виктория Алексеевна',
                description: 'Проректор по учебной работе',
                phones: ['8 (800) 3333-77-6 (доб. 23)'],
                emails: ['v.smarysheva@eeip.ru'],
                avatarLink: '../image/peoples/00014.jpg'
            },
            {
                title: 'Аравина Анна Игоревна',
                description: 'Начальник отдела "Инновационные технологии в образовании',
                phones: ['8 800 3333 77 6 (доб. 90)'],
                emails: ['ivashkina.anna@eeip.ru'],
                avatarLink: '../image/peoples/00005.jpg'
            },
            {
                title: 'Тяпин Алексей Аркадьевич',
                description: 'Начальник Управления по развитию и цифровизации',
                phones: ['8 800 3333 77 6 (доб. 99)'],
                emails: ['alex.tyapin@eeip.ru '],
                avatarLink: '../image/peoples/00016.jpg'
            },
            {
                title: 'Олейников Максим Сергеевич',
                description: 'Начальник отдела автоматизации и компьютерного обеспечения',
                phones: ['8 (800) 3333-77-6 (доб. 8)'],
                emails: ['maxim.oleynikov@eeip.ru'],
                avatarLink: '../image/peoples/00009.jpg'
            },
            {
                title: 'Французова Ирина Олеговна',
                description: 'Главный бухгалтер',
                phones: ['8 (812) 235-58-10'],
                emails: ['irina.frantsuzova@eeip.ru'],
                avatarLink: '../image/peoples/00017.jpg'
            },
            {
                title: 'Рябенко Ольга Александровна',
                description: 'Бухгалтер',
                phones: ['8 (812) 385-76-71'],
                emails: ['olga.ryabenko@eeip.ru'],
                avatarLink: '../image/peoples/00012.jpg'
            }
        ]
    }
};
